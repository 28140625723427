import styles from './styles.module.css';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { CSSProperties, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { OnSubmit } from 'react-hook-form/dist/types';
import { CustomButton, CustomButtonProps } from 'src/components/formComponents/customButton';
import { CustomCheckbox } from 'src/components/formComponents/customCheckbox';
import { CustomInput } from 'src/components/formComponents/customInput';
import { CustomRadioGroup } from 'src/components/formComponents/customRadioGroup';
import { CaretIcon } from 'src/icons/caret';
import { Upload } from 'src/icons/upload';

const MAX_TEXT_SIZE = 150;
const MAX_FILE_SIZE = 10_485_760; // bytes

enum MembershipTypes {
	Exporter = 'exporter',
	Associate = 'associate',
}

export interface MembershipFormValues {
	areYouAHuman: boolean;
	companyName: string;
	tradingName: string;
	abn: string;
	primaryContact: string;
	position: string;
	email: string;
	businessNumber: string;
	mobileNumber: string;
	postalAddress: string;
	suburbTownPostal: string;
	statePostal: string;
	postcodePostal: string;
	businessAddress: string;
	suburbTownBusiness: string;
	stateBusiness: string;
	postcodeBusiness: string;
	membershipType: MembershipTypes;
	associateUnderstandMembershipFee: boolean;
	associateTermsAndConditions: boolean;
	associateAgreeConstitution: boolean;
	associateAllStatementsCorrect: boolean;
	associateResumeAttachment: FileList;
	associateReferenceAttachment: FileList;
	exporterCattle?: boolean;
	exporterSheep?: boolean;
	exporterGoats?: boolean;
	exporterOther?: boolean;
	exporterHoldLicence?: boolean;
	exporterLicenceNumber?: string;
	exporterLicenceAttachment: FileList;
	exporterMembershipConditions: boolean;
	exporterAgreeConstitution?: boolean;
	exporterAgreeShareApplication?: boolean;
	exporterAllStatementsCorrect?: boolean;
}

const textValidator = (value: string, inputName: string) => {
	if (value.trim().length < 1) {
		return `${inputName} is required`;
	}
	if (value.length >= MAX_TEXT_SIZE) {
		return `${inputName} is too long`;
	}
};

const validateFileRequired = (value: FileList, message: string) => {
	if (value?.length && value.length > 0) {
		return undefined;
	}
	return message;
};

const validateFileSize = (value: FileList) => {
	if (value) {
		if (value.length > 1) {
			return 'Select only one file';
		}
		if (value.length === 1 && value[0].size > MAX_FILE_SIZE) {
			return `The selected file is larger than ${Math.trunc(
				MAX_FILE_SIZE / 1024 / 1024
			)}Mb, select a smaller file`;
		}
	}
};

const validateFileType = (value: FileList, acceptedTypes: string[], message: string) => {
	if (value && value.length === 1) {
		if (!acceptedTypes.includes(value[0].type)) {
			return message;
		}
	}
};

const validFiles = {
	associate: {
		resume: ['application/pdf'],
		reference: ['application/pdf'],
	},
	industry: {
		resume: ['application/pdf'],
		reference: ['application/pdf'],
	},
	exporter: {
		licence: ['application/pdf', 'image/png', 'image/jpeg'],
	},
};

const getRules = (getValues: any) => ({
	companyName: {
		required: {
			value: true,
			message: 'Company Name is required',
		},
		validate: (value: string) => textValidator(value, 'Company Name'),
	}, // string;
	tradingName: {
		required: {
			value: true,
			message: 'Trading Name is required',
		},
		validate: (value: string) => textValidator(value, 'Trading Name'),
	}, // string;
	abn: {
		required: {
			value: true,
			message: 'ABN is required',
		},
		validate: (value: string) => textValidator(value, 'ABN'),
	}, //string;
	primaryContact: {
		required: {
			value: true,
			message: 'Primary Contact is required',
		},
		validate: (value: string) => textValidator(value, 'Primary Contact'),
	}, // string;
	position: {
		required: {
			value: true,
			message: 'Position is required',
		},
	}, // string;
	email: {
		required: {
			value: true,
			message: 'Email is required',
		},
		validate: (value: string) => textValidator(value, 'Email'),
	}, //string;
	businessNumber: {
		required: {
			value: true,
			message: 'Business Number is required',
		},
	}, // string;
	mobileNumber: {
		required: {
			value: true,
			message: 'Mobile Number is required',
		},
	}, // string;
	businessAddress: {
		required: {
			value: true,
			message: 'Business Address is required',
		},
	}, // string;
	suburbTownBusiness: {
		required: {
			value: true,
			message: 'Suburb/Town is required',
		},
	}, // string;
	stateBusiness: {
		required: {
			value: true,
			message: 'State is required',
		},
	}, // string;
	postcodeBusiness: {
		required: {
			value: true,
			message: 'Postcode is required',
		},
	}, // string;
	postalAddress: {}, // string;
	suburbTownPostal: {}, // string;
	statePostal: {}, // string;
	postcodePostal: {}, // string;
	membershipType: {
		required: {
			value: true,
			message: 'membershipType is required',
		},
	}, // 'exporter' | 'associate';
	associateResumeAttachment: {
		validate: (value: FileList) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return (
				validateFileRequired(value, 'Please attach your resume') ||
				validateFileType(value, validFiles.associate.resume, 'Please attach a PDF file') ||
				validateFileSize(value)
			);
		},
	},
	associateReferenceAttachment: {
		validate: (value: FileList) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return (
				validateFileRequired(value, 'Please attach a reference') ||
				validateFileType(value, validFiles.associate.reference, 'Please attach a PDF file') ||
				validateFileSize(value)
			);
		},
	},
	associateUnderstandMembershipFee: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return value || 'Please indicate that you understand the Membership Fee terms and conditions';
		},
	}, // boolean;
	associateTermsAndConditions: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return value || 'Please indicate that you have read and understood the terms and conditions';
		},
	}, // boolean;
	associateAgreeConstitution: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return value || 'Please indicate that you agree to be bound by the constitution of LiveCorp';
		},
	}, // boolean;
	associateAllStatementsCorrect: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Associate) {
				return;
			}
			return (
				value || 'Please indicate that all your statements entered in the application are correct'
			);
		},
	}, // boolean;
	exporterCattle: {}, //boolean;
	exporterSheep: {}, //boolean;
	exporterGoats: {}, //boolean;
	exporterOther: {}, //boolean;
	exporterHoldLicence: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return value || 'Please indicate that you hold a current Australian livestock export licence';
		},
	}, //boolean;
	exporterLicenceNumber: {
		validate: (value: string) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			const holdLicence = getValues('exporterHoldLicence');
			if (holdLicence) {
				return textValidator(value, 'Licence Number');
			}
		},
	}, //string;
	exporterLicenceAttachment: {
		validate: (value: FileList) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return (
				validateFileRequired(value, 'Attach a copy of your current livestock export licence') ||
				validateFileType(
					value,
					validFiles.exporter.licence,
					'Please attach a PDF file, or an image (JEPG/JPG or PNG)'
				) ||
				validateFileSize(value)
			);
		},
	},
	exporterMembershipConditions: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return value || 'Please indicate that you have read and understood the terms and conditions';
		},
	}, //boolean;
	exporterAgreeConstitution: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return value || 'Please indicate that you agree to be bound by the constitution of LiveCorp';
		},
	}, //boolean;
	exporterAgreeShareApplication: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return (
				value || 'Please indicate that you agree for your application to be forwarded to the ALEC'
			);
		},
	}, //boolean;
	exporterAllStatementsCorrect: {
		validate: (value: any) => {
			const membershipType = getValues('membershipType');
			if (membershipType !== MembershipTypes.Exporter) {
				return;
			}
			return (
				value || 'Please indicate that all your statements entered in the application are correct'
			);
		},
	}, //boolean;
});

const membershipOptions = [
	{
		value: MembershipTypes.Exporter,
		label: 'Exporter Membership',
	},
	{
		value: MembershipTypes.Associate,
		label: 'Associate Membership',
		selected: true,
	},
];

const membershipTypeContainerStyle: CSSProperties = {
	display: 'inline-flex',
	flexDirection: 'column',
};

enum SubmitState {
	NotSubmitted,
	Submitting,
	SuccessfullySubmitted,
	UnsuccessfullySubmitted,
}

export interface MembershipFormResult {
	status: 'success' | 'error';
	errorMessage?: string;
}

interface Props {
	onSubmit?: (data: MembershipFormValues) => Promise<MembershipFormResult>;
}

export const MembershipForm = (props: Props) => {
	const {
		register,
		handleSubmit,
		errors,
		watch,
		reset,
		getValues,
		triggerValidation,
		setValue,
	} = useForm<MembershipFormValues>();
	const [selectedMembershipType, setSelectedMembershipType] = useState(MembershipTypes.Associate);
	const [submitState, setSubmitState] = useState(SubmitState.NotSubmitted);
	const [submitError, setSubmitError] = useState<string | undefined>(undefined);
	const rules = getRules(getValues);

	const onSubmit: OnSubmit<MembershipFormValues> = async (data) => {
		setSubmitState(SubmitState.Submitting);
		if (props.onSubmit) {
			const result = await props.onSubmit(data);
			if (result.status === 'success') {
				setSubmitState(SubmitState.SuccessfullySubmitted);
			} else {
				setSubmitState(SubmitState.UnsuccessfullySubmitted);
				setSubmitError(result.errorMessage);
			}
		}
	};

	useEffect(() => {
		setValue(getResetConditionalFields());
	}, [selectedMembershipType]);

	useEffect(() => {
		if (submitState === SubmitState.SuccessfullySubmitted) {
			const allFields = [
				...getResetConditionalFields(),
				...[
					{ companyName: undefined },
					{ tradingName: undefined },
					{ abn: undefined },
					{ primaryContact: undefined },
					{ position: undefined },
					{ email: undefined },
					{ businessNumber: undefined },
					{ mobileNumber: undefined },
					{ businessAddress: undefined },
					{ suburbTownBusiness: undefined },
					{ stateBusiness: undefined },
					{ postcodeBusiness: undefined },
					{ postalAddress: undefined },
					{ suburbTownPostal: undefined },
					{ statePostal: undefined },
					{ postcodePostal: undefined },
				],
			];
			setValue(allFields);
		}
	}, [submitState]);

	const getResetConditionalFields = () => [
		{ associateResumeAttachment: undefined },
		{ associateReferenceAttachment: undefined },
		{ associateUnderstandMembershipFee: undefined },
		{ associateTermsAndConditions: undefined },
		{ associateAgreeConstitution: undefined },
		{ associateAllStatementsCorrect: undefined },
		{ exporterCattle: undefined },
		{ exporterSheep: undefined },
		{ exporterGoats: undefined },
		{ exporterOther: undefined },
		{ exporterHoldLicence: undefined },
		{ exporterLicenceNumber: undefined },
		{ exporterLicenceAttachment: undefined },
		{ exporterMembershipConditions: undefined },
		{ exporterAgreeConstitution: undefined },
		{ exporterAgreeShareApplication: undefined },
		{ exporterAllStatementsCorrect: undefined },
	];

	const onReset = () => {
		setSubmitState(SubmitState.NotSubmitted);
		setSubmitError(undefined);
		reset();
	};

	const watchMembershipType = watch(
		'membershipType',
		membershipOptions.find((o) => o.selected)?.value as MembershipTypes
	);

	if (watchMembershipType !== selectedMembershipType) {
		setSelectedMembershipType(watchMembershipType);
	}

	const forAssociateMembershipSection = (
		<div className={styles.note}>
			<div className={styles.formSectionTitle}>For Associate Membership:</div>
			<p className={styles.customParagraph}>
				Please include a short resume of the organisation, including details of any contribution to
				or significant involvement with the Australian livestock export industry, and a reference
				(these must be attached for this membership application to be valid):
			</p>
			<p>
				<CustomButton
					name="associateResumeAttachment"
					type="file"
					accept={validFiles.associate.resume.join(',')}
					content="Upload Resume"
					required
					iconLeft={<Upload style={{ marginRight: '20px' }} />}
					inlineStyle={{ marginLeft: '0' }}
					variant="secondary"
					ref={register(rules.associateResumeAttachment)}
				/>
				<CustomButton
					name="associateReferenceAttachment"
					type="file"
					accept={validFiles.associate.reference.join(',')}
					content="Upload Reference"
					required
					iconLeft={<Upload style={{ marginRight: '20px' }} />}
					variant="secondary"
					ref={register(rules.associateReferenceAttachment)}
				/>
			</p>
			{errors?.associateResumeAttachment?.message && (
				<span className={styles.errorMessage}>{errors?.associateResumeAttachment?.message}</span>
			)}
			{errors?.associateReferenceAttachment?.message && (
				<span className={styles.errorMessage}>{errors?.associateReferenceAttachment?.message}</span>
			)}
			<CustomCheckbox
				errorMessage={errors?.associateUnderstandMembershipFee?.message as string}
				name="associateUnderstandMembershipFee"
				labelJsx={
					'I/we understand that there is an annual Associate Membership fee and if the annual membership fee is not paid in full by the due date this Associate Membership will cease'
				}
				required
				labelClassName={styles.checkboxLabel}
				ref={register(rules.associateUnderstandMembershipFee)}
			/>
			<CustomCheckbox
				errorMessage={errors?.associateTermsAndConditions?.message as string}
				name="associateTermsAndConditions"
				required
				labelJsx={
					<>
						I/We have read and understand the{' '}
						<AnchorLink to="/conditions-of-membership" className={styles.customLink}>
							Conditions of Membership
						</AnchorLink>
					</>
				}
				labelClassName={styles.checkboxLabel}
				ref={register(rules.associateTermsAndConditions)}
			/>
			<CustomCheckbox
				errorMessage={errors?.associateAgreeConstitution?.message as string}
				name="associateAgreeConstitution"
				required
				labelJsx={
					<>
						I/ We agree to be bound by the Constitution of the Australian Livestock Export
						Corporation Ltd (LiveCorp). A copy of the constitution can be requested by emailing{' '}
						<a href="mailto:livecorp@livecorp.com.au" className={styles.customLink}>
							livecorp@livecorp.com.au
						</a>
					</>
				}
				labelClassName={styles.checkboxLabel}
				ref={register(rules.associateAgreeConstitution)}
			/>
			<CustomCheckbox
				errorMessage={errors?.associateAllStatementsCorrect?.message as string}
				name="associateAllStatementsCorrect"
				required
				labelJsx={
					'To the best of my/our knowledge and belief, all statements in this membership application are correct'
				}
				labelClassName={styles.checkboxLabel}
				ref={register(rules.associateAllStatementsCorrect)}
			/>
		</div>
	);

	const forExporterMembershipSection = (
		<div className={styles.note}>
			<div className={styles.formSectionTitle}>For Exporter Membership:</div>
			<div className={styles.speciesContainer}>
				<p>I/We export the following species:</p>
				<CustomCheckbox
					errorMessage={errors?.exporterCattle?.message as string}
					name="exporterCattle"
					labelJsx={'Cattle'}
					labelClassName={styles.checkboxLabel}
					ref={register}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterSheep?.message as string}
					name="exporterSheep"
					labelJsx={'Sheep'}
					labelClassName={styles.checkboxLabel}
					ref={register}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterGoats?.message as string}
					name="exporterGoats"
					labelJsx={'Goats'}
					labelClassName={styles.checkboxLabel}
					ref={register}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterOther?.message as string}
					name="exporterOther"
					labelJsx={'Other'}
					labelClassName={styles.checkboxLabel}
					ref={register}
				/>
			</div>
			<div>
				<CustomCheckbox
					errorMessage={errors?.exporterHoldLicence?.message as string}
					name="exporterHoldLicence"
					labelJsx={'I/We hold a current Australian livestock export licence'}
					required
					labelClassName={styles.checkboxLabel}
					containerStyle={{ marginBottom: '10px' }}
					ref={register(rules.exporterHoldLicence)}
					onClick={() => {
						triggerValidation('exporterLicenceNumber');
					}}
				/>
				<CustomInput
					name="exporterLicenceNumber"
					placeHolder="Your exporter licence number"
					required
					type="text"
					containerStyle={{ maxWidth: '450px', marginBottom: '15px' }}
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.exporterLicenceNumber)}
					errorMessage={errors?.exporterLicenceNumber?.message as string}
				/>
				<p className={styles.customParagraph}>
					Attach a copy of your current livestock export licence (this must be attached for this
					membership application to be valid)
				</p>
				<CustomButton
					name="exporterLicenceAttachment"
					required
					type="file"
					accept={validFiles.exporter.licence.join(',')}
					iconLeft={<Upload style={{ marginRight: '20px' }} />}
					content="Upload Licence"
					inlineStyle={{ margin: '15px 0' }}
					variant="secondary"
					ref={register(rules.exporterLicenceAttachment)}
				/>
				{errors?.exporterLicenceAttachment?.message && (
					<span className={styles.errorMessage}>{errors?.exporterLicenceAttachment?.message}</span>
				)}
			</div>
			<div>
				<CustomCheckbox
					errorMessage={errors?.exporterMembershipConditions?.message as string}
					name="exporterMembershipConditions"
					required
					labelJsx={
						<>
							I/ We have read and understand the{' '}
							<AnchorLink to="/conditions-of-membership" className={styles.customLink}>
								Conditions of Membership
							</AnchorLink>
						</>
					}
					labelClassName={styles.checkboxLabel}
					ref={register(rules.exporterMembershipConditions)}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterAgreeConstitution?.message as string}
					name="exporterAgreeConstitution"
					required
					labelJsx={
						<>
							I/ We agree to be bound by the Constitution of the Australian Livestock Export
							Corporation Ltd (LiveCorp). A copy of the constitution can be requested by emailing{' '}
							<a href="mailto:livecorp@livecorp.com.au" className={styles.customLink}>
								livecorp@livecorp.com.au
							</a>
						</>
					}
					labelClassName={styles.checkboxLabel}
					ref={register(rules.exporterAgreeConstitution)}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterAgreeShareApplication?.message as string}
					name="exporterAgreeShareApplication"
					required
					labelJsx={
						"I/We agree this application can be forwarded to the Australian Livestock Exporter's Council (ALEC)"
					}
					labelClassName={styles.checkboxLabel}
					ref={register(rules.exporterAgreeShareApplication)}
				/>
				<CustomCheckbox
					errorMessage={errors?.exporterAllStatementsCorrect?.message as string}
					name="exporterAllStatementsCorrect"
					required
					labelJsx={
						'To the best of my/our knowledge and belief, all statements in this membership application are correct'
					}
					labelClassName={styles.checkboxLabel}
					ref={register(rules.exporterAllStatementsCorrect)}
				/>
			</div>
		</div>
	);

	const getButtonJsx = () => {
		let buttonProps: CustomButtonProps = {
			type: 'submit',
			disabled: false,
			content: 'Submit',
			iconRight: <CaretIcon paddingPx={{ left: 80 }} />,
			variant: 'primary',
			inlineStyle: { margin: '0' },
		};
		switch (submitState) {
			case SubmitState.Submitting:
				buttonProps.content = 'Submitting';
				buttonProps.disabled = true;
				break;
			case SubmitState.SuccessfullySubmitted:
				buttonProps.content = 'Submitted';
				buttonProps.disabled = true;
				buttonProps.iconRight = undefined;
				break;
			case SubmitState.UnsuccessfullySubmitted:
				buttonProps.content = 'Reset';
				buttonProps.type = 'reset';
				buttonProps.variant = 'secondary';
				break;
		}

		return <CustomButton {...buttonProps} />;
	};

	return (
		<form className={styles.gridForm} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
			<div className={styles.areYouAHuman}>
				<CustomCheckbox
					name="areYouAHuman"
					labelJsx={'Please leave this un--ticked'}
					ref={register}
					tabIndex={-1}
					autoComplete="off"
				/>
			</div>
			<div className={styles.formSectionTitle}>Company Details</div>
			<div className={styles.grid3cols}>
				<CustomInput
					name="companyName"
					required
					placeHolder="Company Name"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.companyName)}
					errorMessage={errors?.companyName?.message as string}
				/>
				<CustomInput
					name="tradingName"
					required
					placeHolder="Trading Name"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.tradingName)}
					errorMessage={errors?.tradingName?.message as string}
				/>
				<CustomInput
					name="abn"
					required
					placeHolder="ABN"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.abn)}
					errorMessage={errors?.abn?.message as string}
				/>
			</div>
			<div className={styles.formSectionTitle}>Contact Details</div>
			<div className={styles.grid3cols}>
				<CustomInput
					name="primaryContact"
					required
					placeHolder="Primary Contact"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.primaryContact)}
					errorMessage={errors?.primaryContact?.message as string}
				/>
				<CustomInput
					name="position"
					required
					placeHolder="Position"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.position)}
					errorMessage={errors?.position?.message as string}
				/>
				<CustomInput
					name="email"
					required
					placeHolder="Email"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.email)}
					errorMessage={errors?.email?.message as string}
				/>
				<CustomInput
					name="businessNumber"
					required
					placeHolder="Business Number"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.businessNumber)}
					errorMessage={errors?.businessNumber?.message as string}
				/>
				<CustomInput
					name="mobileNumber"
					required
					placeHolder="Mobile Number"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.mobileNumber)}
					errorMessage={errors?.mobileNumber?.message as string}
				/>
				<div></div>
				<div className={styles.span3}>
					<CustomInput
						name="businessAddress"
						required
						placeHolder="Business Address"
						type="text"
						outerContainerStyle={{ margin: '0' }}
						ref={register(rules.businessAddress)}
						errorMessage={errors?.businessAddress?.message as string}
					/>
				</div>
				<CustomInput
					name="suburbTownBusiness"
					required
					placeHolder="Suburb/Town"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.suburbTownBusiness)}
					errorMessage={errors?.suburbTownBusiness?.message as string}
				/>
				<CustomInput
					name="stateBusiness"
					required
					placeHolder="State"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.stateBusiness)}
					errorMessage={errors?.stateBusiness?.message as string}
				/>
				<CustomInput
					name="postcodeBusiness"
					required
					placeHolder="Postcode"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.postcodeBusiness)}
					errorMessage={errors?.postcodeBusiness?.message as string}
				/>
				<div className={styles.span3}>
					<CustomInput
						name="postalAddress"
						placeHolder="Postal Address (If different from business address)"
						type="text"
						outerContainerStyle={{ margin: '0' }}
						ref={register(rules.postalAddress)}
						errorMessage={errors?.postalAddress?.message as string}
					/>
				</div>
				<CustomInput
					name="suburbTownPostal"
					placeHolder="Suburb/Town"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.suburbTownPostal)}
					errorMessage={errors?.suburbTownPostal?.message as string}
				/>
				<CustomInput
					name="statePostal"
					placeHolder="State"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.statePostal)}
					errorMessage={errors?.statePostal?.message as string}
				/>
				<CustomInput
					name="postcodePostal"
					placeHolder="Postcode"
					type="text"
					outerContainerStyle={{ margin: '0' }}
					ref={register(rules.postcodePostal)}
					errorMessage={errors?.postcodePostal?.message as string}
				/>
			</div>
			<div className={styles.formSectionTitle}>Type of Membership</div>
			<div>
				<CustomRadioGroup
					name="membershipType"
					ref={register(rules.membershipType)}
					errorMessage={errors?.membershipType?.message as string}
					optionContainerStyle={membershipTypeContainerStyle}
					labelContainerStyle={{ marginBottom: '10px' }}
					options={membershipOptions}
				/>
			</div>
			{/* must be done with css display. All inputs must be in DOM to avoid react-hook-form bug where input fields get mixed-up when adding/removing from DOM */}
			<div
				style={{
					display: selectedMembershipType === MembershipTypes.Associate ? 'block' : 'none',
				}}
			>
				{forAssociateMembershipSection}
			</div>
			<div
				style={{
					display: selectedMembershipType === MembershipTypes.Exporter ? 'block' : 'none',
				}}
			>
				{forExporterMembershipSection}
			</div>
			<div className={styles.privacyCollectionNoticeContainer}>
				<div className={styles.formSectionTitle}>Privacy collection notice</div>
				<p className={styles.customParagraph}>
					The Australian Livestock Export Corporation Ltd (<b className={styles.bold}>LiveCorp</b>)
					is committed to respecting the privacy of the personal information of individuals. The
					personal information on this form is being collected to enable LiveCorp to carry out its
					functions, which include, but are not limited to, the processing of applications for
					membership. Personal information obtained will be used and disclosed for the purpose of
					processing your application for membership, those purposes set out in our Privacy Policy
					and where authorised or required by law. If we do not collect this personal information
					from you, we may be unable to process your application or obtain approval for your
					membership. By providing your personal information to us, you consent to the collection of
					that information for the purposes outlined above. If you do not wish to provide your
					consent, please do not provide your personal information to us. Your consent operates
					until such time as you tell us that you wish to withdraw your consent. For further
					information, including our contact details, please refer to LiveCorp's Privacy Policy,
					which is available on{' '}
					<AnchorLink to="/privacy-policy" className={styles.customLink}>
						our website
					</AnchorLink>
				</p>
			</div>
			<div className={styles.submit}>{getButtonJsx()}</div>
			{submitState === SubmitState.UnsuccessfullySubmitted && (
				<div className={styles.submitMessageContainer}>
					<p>{submitError ? submitError : 'An error has ocurred, please try again later.'}</p>
				</div>
			)}
			{submitState === SubmitState.SuccessfullySubmitted && (
				<div className={styles.submitMessageContainer}>
					<p>Thank you, your details have been submitted.</p>
				</div>
			)}
		</form>
	);
};
