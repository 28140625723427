import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

interface Props {
	options: {
		value: string;
		label: string;
		selected?: boolean;
	}[];
	name?: string;
	ref?: any;
	errorMessage?: string;
	// labelJsx: ReactNode;
	className?: string;
	containerStyle?: CSSProperties;
	optionContainerStyle?: CSSProperties;
	errorMessageStyle?: CSSProperties;
	labelContainerStyle?: CSSProperties;
}

export const CustomRadioGroup = React.forwardRef((props: Props, ref: any) => {
	const {
		options,
		name,
		// labelJsx,
		errorMessageStyle,
		optionContainerStyle,
		containerStyle,
		errorMessage,
		labelContainerStyle,
		className,
	} = props;

	const containerClass = classNames([styles.container, className]);

	return (
		<div className={containerClass} style={containerStyle}>
			{/* {labelJsx} */}
			<div className={styles.optionContainer} style={optionContainerStyle}>
				{options.map((o, index) => (
					<label
						className={styles.label}
						style={labelContainerStyle}
						key={`${name}_option_${index}`}
					>
						<input
							type="radio"
							name={name}
							// checked={!!o.selected}
							defaultChecked={!!o.selected}
							className={styles.inputRadio}
							value={o.value}
							ref={ref}
						/>
						<span className={styles.labelSpan}>{o.label}</span>
					</label>
				))}
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
});
