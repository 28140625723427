import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode, ReactEventHandler } from 'react';

interface Props {
	name?: string;
	required?: boolean;
	labelJsx: ReactNode;
	ref?: any;
	errorMessage?: string;
	tabIndex?: number;
	autoComplete?: string;
	labelClassName?: string;
	containerStyle?: CSSProperties;
	checkboxContainerStyle?: CSSProperties;
	labelContainerStyle?: CSSProperties;
	errorMessageStyle?: CSSProperties;
	onClick?: ReactEventHandler<HTMLElement>;
}

export const CustomCheckbox = React.forwardRef((props: Props, ref: any) => {
	const {
		name,
		labelJsx,
		tabIndex,
		autoComplete,
		errorMessage,
		containerStyle,
		checkboxContainerStyle,
		errorMessageStyle,
		labelContainerStyle,
		labelClassName,
		onClick,
		required,
	} = props;

	return (
		<div className={styles.container} style={containerStyle}>
			<div className={styles.optionContainer} style={checkboxContainerStyle}>
				<label className={styles.label} onClick={onClick}>
					<input
						type="checkbox"
						name={name}
						id={name}
						ref={ref}
						tabIndex={tabIndex}
						autoComplete={autoComplete}
						className={styles.inputCheckbox}
					/>
					<span className={styles.labelSpan}></span>
					<span className={labelClassName} style={labelContainerStyle}>
						{labelJsx}
						{required ? ' *' : ''}
					</span>
				</label>
			</div>
			<div className={styles.errorMessage} style={errorMessageStyle}>
				{errorMessage}
			</div>
		</div>
	);
});
