import membership from './membership.graphql';
import React from 'react';
import { Helmet } from 'react-helmet';
import { DefaultLayoutTemplate } from '../layout';
import {
	MembershipForm,
	MembershipFormValues,
	MembershipFormResult,
} from 'src/components/formComponents/membershipForm';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import { ApolloManager } from 'src/utils/apollo-manager';
import { curatedTitle } from 'src/utils/curated-title';

interface MembershipResult {
	result: string;
}

export const MembershipPage = () => {
	const onSubmit = async (data: MembershipFormValues): Promise<MembershipFormResult> => {
		try {
			await ApolloManager.client.mutate<MembershipResult>({
				mutation: membership,
				variables: {
					isItARobot: data.areYouAHuman, // to make it less obvious to bots.
					companyName: data.companyName,
					tradingName: data.tradingName,
					abn: data.abn,
					primaryContact: data.primaryContact,
					position: data.position,
					email: data.email,
					businessNumber: data.businessNumber,
					mobileNumber: data.mobileNumber,
					postalAddress: data.postalAddress,
					suburbTownPostal: data.suburbTownPostal,
					statePostal: data.statePostal,
					postcodePostal: data.postcodePostal,
					businessAddress: data.businessAddress,
					suburbTownBusiness: data.suburbTownBusiness,
					stateBusiness: data.stateBusiness,
					postcodeBusiness: data.postcodeBusiness,
					membershipType: data.membershipType,
					exporterCattle: data.exporterCattle,
					exporterSheep: data.exporterSheep,
					exporterGoats: data.exporterGoats,
					exporterOther: data.exporterOther,
					exporterHoldLicence: data.exporterHoldLicence,
					exporterLicenceNumber: data.exporterLicenceNumber,
					exporterLicenceAttachment:
						(data.exporterLicenceAttachment?.length || 0) > 0
							? data.exporterLicenceAttachment[0]
							: undefined,
					exporterMembershipConditions: data.exporterMembershipConditions,
					exporterAgreeConstitution: data.exporterAgreeConstitution,
					exporterAgreeShareApplication: data.exporterAgreeShareApplication,
					exporterAllStatementsCorrect: data.exporterAllStatementsCorrect,
					associateResumeAttachment:
						(data.associateResumeAttachment?.length || 0) > 0
							? data.associateResumeAttachment[0]
							: undefined,
					associateReferenceAttachment:
						(data.associateReferenceAttachment?.length || 0) > 0
							? data.associateReferenceAttachment[0]
							: undefined,
					associateUnderstandMembershipFee: data.associateUnderstandMembershipFee,
					associateTermsAndConditions: data.associateTermsAndConditions,
					associateAgreeConstitution: data.associateAgreeConstitution,
					associateAllStatementsCorrect: data.associateAllStatementsCorrect,
				},
			});
			return {
				status: 'success',
			};
		} catch (error) {
			console.error('Error Submitting Membership Form: ', error, data);
		}
		return {
			status: 'error',
			errorMessage:
				'There was an error processing your submission. Please try again later or contact us by phone. We apologise for the inconvenience',
		};
	};

	const title = 'Become a Member';

	return (
		<SectionContainer isGrey withBorderTop>
			<Helmet>
				<title>{curatedTitle(title)}</title>
			</Helmet>
			<SectionContainerTitle text={title} titleStyle={{ color: '#323E6B' }} />
			<MembershipForm onSubmit={onSubmit} />
		</SectionContainer>
	);
};

export default () => (
	<DefaultLayoutTemplate>
		<MembershipPage />
	</DefaultLayoutTemplate>
);
